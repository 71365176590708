/* -------------------- General Site layouts -------------------- **/
*{
	font-family: $font-stack;
//	color: #6e6e70;
	text-decoration: none !important;
	outline: none !important;
	-webkit-font-smoothing: antialiased;
}

body{
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
}

img{
	max-width: 100%;
}


/*** Page ***/
.PageResults,
.InnerPage{
	.banner{
		height: 70vh;
	}
	.rightLinksContainer{
	position: fixed;
	}
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: #1f5865;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}


.eventTitle{
	color: #333 !important;
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;

	h3{
  		color: $themeColor;
  		font-size: 1.5em;
	}
	img{
		@include border-radius(50%);
	}
}



.noticesBG{
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
	padding: 30px;
	background-color: #fff;
	max-width: 100%;
	width: 500px;
}
.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    font-size: 1.3em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 2px solid #333;
    padding: 3px 5px;
    margin-top: 10px;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	top: 0px;
	left: 50%;
	@include transform($center);
	padding-right: 20px;
	color: $themeColor;
	font-size: 0.8em;
	cursor: pointer;
	z-index: 9999; 
	img{
		width: 30px;
	}
}

.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;
	*{
		color: #333;
	}
	.col-12{
		padding: 20px 0px;
		border-bottom: 2px solid #cccccc;
	}

}

.MainMenuClose{
	display: none !important;
}

.mainContent{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	    max-width: 100%;
	 
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    max-width: 100%;
			background-color: #1b3080;
			&:hover{
		    	background-color: #5a5962 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
.addToCalendar,
.attachment,
.readMoreLink,
.button{
		padding: 10px 20px !important;
	    display: inline-block;
	    text-align: center;
	    color: #fff;
	    font-size: 0.7em !important;
	    cursor: pointer;
	    *{
	    	font-size: 1em;
	    	color: #fff;

	    @include transition($t1);
	    }
	    border: none !important;
	    color: #fff !important;
	    font-weight: lighter; 
	    margin-top: 10px;
	    @include transition($t1);
	    @include border-radius(3px);
	   	background-color: $themeColor;
		&:hover{
	    	background-color: #4b4b4b !important;
	    }
}

/** Inputs  **/
#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    background-color: $themeColor;
			max-width: none !important;
		    width: auto !important;
		    display: inline-block;

			&:hover{
	    	    background-color: #71142b !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
/** Inputs  **/



.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.heading-2{
	color: $themeColor !important;font-weight: lighter;
	*{color: $themeColor !important;font-size: 1em;font-weight: lighter;}
}
.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px !important;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
		@include transition($t1);
			color: #fff !important;
		}
		
		@include border-radius(3px);

	    background-color: $themeColor !important;

		 &:hover{
	    	    background-color: #454545 !important;
	    		*{
	    			color: #fff !important;
	    		}
		    }
	}
}

.SubDate{
	margin-top: 20px;
    font-size: 1.4em;
    margin-bottom: 10px;
}


.archive{
	*{
		color: #fff !important;
	}
}
/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload{
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	float: left;
	padding: 10px 45px 12px 30px;
	border: 1px solid $themeColor;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		text-transform: uppercase;
		font-size: 1em;
		font-weight: normal !important;
		color: $themeColor !important;
	    display: inline-block;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon{


		position: absolute;
		right: 10px;
		top: 50%;
		@include transform(translateY(-50%));



	    background-color: $themeColor;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span{
	    	@include transition($t1);
	    	color: #fff !important;
		    background-color: #000;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover{
		background-color: #e2e0df !important;
		color: #1b3080 !important;
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
	}
}


.upload{
	/*padding: 10px;
	border-bottom: 2px solid #eee;*/
	*{
		vertical-align: middle;
		font-weight: lighter !important;
	}
}





.galleryImage{
	display: block;
	width: 33.33%;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff !important;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	width: 33.33%;
    float: left;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}
#CustomLoginForm_LoginForm{
	max-width: 400px;
	width: 100%;
}

.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		text-transform: uppercase;
		font-weight: lighter;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic img{
	display: inline-block;
	padding-bottom: 20px;
}

.VerticalStack{
	.profilePic img{
		display: inline-block;
		padding-bottom: 0px;
	}
}


#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
	.readMoreLink{
		font-size: 1em !important;
	}
}


.NewsletterItem{
	display: block;
	width: 25%;
	float: left;
	padding: 10px;
	.NewsletterInner{
		display: block;
		position: relative;
		width: 100%;
		@include border-radius(3px);
		@include transition($t1);
		border: 5px solid #fff;
		@include box-shadow(0px 0px 10px 0px #9c9c9c);
		overflow: hidden;
		.newsletterThumb{
			float: left;
			clear: both;
			background-color: #eee;
			width: 100%;
			background-image: url(../images/newsletter.png);
			background-size: 60%;
    		background-repeat: no-repeat;
			background-position: center;
			padding-bottom: 110%;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	&:hover{
		.NewsletterInner{
			border: 5px solid $themeColor !important;
		}
	}
	.newsletterTitle{
		padding: 10px;
		font-size: 0.8em;
		float: left;
	    width: 100%;
	    text-align: center;
	    clear: both;
	}
}

.Newsletter{
	width: 20%;
    padding-bottom:20%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute; 
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	
    color: #e42c25 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	color: #303c68 !important;
	*{
		font-weight: lighter;
		color: #303c68 !important;
	}
}

.InnerPage{
    .calPrev,
    .calNext {
        top: 37px;
    }
    .calendar-widget-table{
    border: none !important;
    }
    .calendar-widget-table,
    .outerCalendarContainer{
        width: 100%;
    }
    .eventContainer{
        display: none !important;
    }
    .calHeader{
        .col-7,
        .col-5{
            width: 100%;
            text-align: center;
        }
        .col-5{
            display: none !important;
        }
    }
}
.group1{
	@include transition($t1);
	opacity: 0;
}
.PageLoaded{
	.group1{
		opacity: 1 !important;
	}
}
#gmap_canvas{
	min-height: 400px;
}



.searchToggle{
	right: 69px;
	@include transition($t1);
}

.searchToggleButton{
	position: absolute;
	left: 50%;
	top: 7px;
	@include transform(translateX(-50%));
}

.searchToggle{
	position: absolute;
	z-index: 9999;
	top: 0px;
    cursor: pointer;
	right: 0px;
	height: 41px;
	width: 40px;
	text-align: center;
	padding-top: 8px;
	font-size: 1.1em;
	opacity: 1;
	*{
		color: #dbb95a;
	}
}

/** General Site layouts **/
















.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex{
	display: flex;
}

.slink{
	cursor: pointer;
}


.translate{
	cursor: pointer;
}
.TranslateBG{
//	display: none;
//	position: fixed;
//	top: 0px;
//	z-index: 9999;
//	left: 0px;
//	width: 100%;
//	height: 100%;
//	background-color: rgba(41,37,89,0.76);
}
.translateHolder {
    position: absolute;
    width: 30px;
    top: 25px;
    cursor: pointer;
    right: calc(5% + 210px);
    height: 30px;
    #translate {
        background-image: url(../images/Google_Translate_logo.png);
        background-color: #247B91;
        height: 100%;
        border-radius: 50%;
        background-size: 15px 15px;
        background-position: center;
        background-repeat: no-repeat;
    }
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 12px; //50%;
	left: 50%;
	@include transform($center);
}

#gmap_canvas{
	min-height: 500px;
}


/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:7px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: #1c2023;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: $themeColor;
	@include border-radius(0px); 
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: $themeColor;
}


.TopLevel::-webkit-scrollbar{
	width:4px;
	height:7px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
.TopLevel::-webkit-scrollbar-track-piece{
	background-color: #9c1816;
	@include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb{
	background-color: #ca1916;
	@include border-radius(0px); 
}
.TopLevel::-webkit-scrollbar-thumb:vertical{
}
.TopLevel::-webkit-scrollbar-thumb:horizontal{
}
.TopLevel::-webkit-scrollbar-thumb:hover{
	background-color: #ca1916;
}

.db{
	display: block;
}

fieldset{
	border: none;
}

/*** Search Form ***/


/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	@include background-cover(url("../images/img5.jpg"));

	.darken{
		@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
		background-position: top center;
	}
}

.bannerBg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	@include background-cover(url("../images/bg.jpg"));
		background-position: top center;
}


#google_translate_element{
//    border: 30px solid #312d5e;
}

.searchToggle{
	cursor: pointer;
	z-index: 8000;
	*{
		color: #fff;
	}
	position: fixed;
	top: 5px;
	left: 10px;
	span{
	display: inline-block;
	margin-left: 10px;
	}
}


/*** Page ***/

/*** Footer ***/
footer{
	padding: 30px 0px 0px 0px;
}
#Menu .topMenuLink:after {
    display: block;
    width: 2px;
    height: 20px;
    background-color: #257c91;
    position: absolute;
    content: '';
    right: -1px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.LevelOne:last-of-type .topMenuLink:after{
	display: none !important
}


/*** Menu ***/
#Menu{
	position: absolute;
	bottom: 0px;
	right: calc(5% - 20px);
	float: left;
    z-index: 9999;
    width: 100%;
    text-align: right;
	display: inline-block;
	text-align: right;
	*{
		color: #000;
	}
	/*
	.MenuButton{
		display: inline-block;
		position: absolute;
		z-index: 2;
		cursor: pointer;
		background-color: #73a8d9;
	    right: 0px;
	    padding: 8px 12px 7px 12px;
	    cursor: pointer;
	    opacity: 1;
	    top: 0px;
	    text-transform: uppercase;
	}
	*/
	.MenuContainer{
    	text-align: right;
		position: relative;
		display: inline-block;
		.LevelOne{
			display: inline-block; 
			text-align: center;
			a{    
				font-size: 1em;
				@include transition($t1);
				padding: 14px 10px 14px 10px;
    			font-size: 1em;
			    line-height: 3em;
			    display: inline-block; 
	   	 		line-height: 1.1em; 
	   	 		
			}
		}
	}


	.SubMenu{
		@include box-shadow(-2px 13px 40px 0px rgba(47, 47, 47, 0.33));
		width: 100%;
		height: 0px;
		font-size: 0.7em;
		overflow: hidden;
		display: block;
		z-index: -9999;
		padding-bottom: 15px;
    	left: 50%;
    	text-align: left;
		position: absolute;
	    background-color: rgba(255,255,255, 0.89);
	    border-top: 10px solid $themeColor;
	    border-radius: 0px;
	    margin-top: 0px;
	   /* padding: 3px 10px 6px 10px;*/
	    opacity: 0;
	    transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -o-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -ms-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -moz-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -webkit-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    @include transform(translateY(20px) translateX(-50%) scale(0.98));

	    a{
	    	width: calc(50% - 20px);
	    	margin: 0px 10px;
		    display: block;
		    float: left;
		    padding: 10px 0px !important;
		    /*font-size: 0.9em;*/
		    line-height: 1.2em !important;
		    border-bottom:  1px solid $themeColor;
			background-color: transparent;
	    	@include transition($t1);
			&:hover{
    			color: $themeColor !important;
			}
		}
	}
	.menuItem-1,
	.menuItem-2{
		.SubMenu{
			left: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	}
	.menuItem-Last{
		.SubMenu{
			left: auto;
			right: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	}
	.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(-50%) scale(1) !important);
	}
	.menuItem-1.active .SubMenu,
	.menuItem-2.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(0%) scale(1) !important);
	}
	.menuItem-Last.active .SubMenu{
			opacity: 1 !important;
			left: auto;
			right: 0%;
			@include transform(translateY(0px) translateX(0%) scale(1) !important);	
	}

	/*.SubMenu:before {
	    content: '';
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 17px;
	    left: 0px;
	    top: -14px;
	    z-index: 2;
	}
	.SubMenu:after {
	    content: '';
	    display: block;
	    position: absolute;
	    border: 10px solid transparent;
	    top: -20px;
	    left: 50%;
	    margin-left: -10px;
	    border-bottom: 10px solid #2f3b67;
	}*/
}

.topMenuLink{
	position: relative;
	&:before{
		content: '';	 
		display: block;
		border: 10px solid transparent;
		border-bottom-color: $themeColor;
		position: absolute;
		left: 50%;
		bottom: 0px;
		opacity: 0;
		@include transition($t1);
		@include transform(translateX(-50%));
	}
	&:hover:before{
		opacity: 1 !important
	}
}

.flexslider .flex-direction-nav {
    position: absolute;
    bottom: 16%;
    left: 50%;
    height: 30px;  
    z-index: 800;
    width: calc(100% - 80px);
    @include transform($center);
}
.flexslider .slides li {
    position: relative;
    height: 100vh;
}
.quoteBox {
    position: absolute;
    top: 50%;
    left: 150px;
    @include transform(translate(0%, -50%));
    z-index: 2;
    color: #fff;
    font-size: 2em;
    display: block;
    opacity: 1;
    line-height: 1.2em;
    text-transform: uppercase;
    *{
    	color: #fff !important;
    }
}

.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-image: url(../images/bg.jpg);
}


.policyBar{
	padding: 10px;
	font-size: 0.7em;
	background-color: #2b2b2b;
	color: #fff;
	*{
	color: #fff;
	}
}

.contentPage table{
	border-collapse: collapse;
    border: 1px solid #c7c7c7;
    }
.contentPage table th,
.contentPage table td{
padding: 10px 5px !important;
	border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #c7c7c7;
}

.contentPage table th{
	background-color: #efececd1;
}

.pageList{
	a{
		display: block;
		margin-bottom: 5px;
		background-color: #eee;
		padding: 10px 20px;
		color: #475f79 !important;
		@include transition($t1);
		&:hover{
			background-color: #475f79 !important;
			color: #fff !important;
		} 
	}
}

.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: #1f5865;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}

.calendar-widget-table tbody td{
	background-color: transparent;
}

.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}

/*
|===============================================================
|	Calendar Page Template Widget
|===============================================================
*/
.calendar-widget-table {
	width: 100%;
	background-color: transparent;
	margin:0 0 2em;
	*{
		text-align: center;
	}
	thead {
		tr {
			th {
				border: none;
    			padding: 15px 20px;
				background-color: #475f79;
				    font-size: 1em;
				.show-month, .next, .prev {
					display:inline-block;

					font-weight: bold;
					font-weight: lighter;
    				font-size: 1.3em;
                    color: #6e6e70;
					i { 
					}
					.inner {
						color: #FFFFFF;
						width: auto;
					}
				}
				.show-month {
					padding: 0 0px;
					font-weight: bold;
					text-align: left !important;
					width: 100%;
					font-size: 1.8em;
				}	
			}
		}
	}
	.calendar-header {
		td.calendar-header-day, td {
			background: transparent !important;
			color: #475f79;
			border:none !important;
		}
	}
	tbody{
		.calendar-day {
			border: 1px solid #ececec;
			color: #6a6c71;
			@include transition($t1);
		}
		tr {
			&:nth-child(even){ 
				background-color:transparent; 
			}
			td {
			position: relative;
				padding: 10px 0px !important;
				z-index: 1;
				text-align: center !important;
				.calEventPopup {
					position: absolute;
					z-index: 80 !important;
					padding:20px;
					border:2px solid $LinkColor;
					background-color: #fff;
					    width: 180px;
					    text-align: center;
					    display:none;
					.dayTitle {
						color:$LinkColor;
						margin-bottom:20px;
						font-weight:500;
					}
					.eventLine {
						color:$LinkColor;
					}

				}
				z-index: 1;
				&.hasEvent:hover {
				z-index: 9990;
					.calEventPopup {
						display: block;
						z-index: 90 !important;
					}
				}
				&:nth-child(1) .calEventPopup { margin-left:0; }
				&:nth-child(2) .calEventPopup { margin-left:-20px; }
				&:nth-child(3) .calEventPopup { margin-left:-40px; }
				&:nth-child(4) .calEventPopup { margin-left:50%; @include transform(translateX(-50%)); }
				&:nth-child(5) .calEventPopup { margin-right:-40px; }
				&:nth-child(6) .calEventPopup { margin-right:-20px; }
				&:nth-child(7) .calEventPopup { margin-right:0px; }
			}
		}
		.hasEvent {
			background-repeat: no-repeat;
			background-position: 5px 5px;
			background-size: 4px 4px;
			background-image: none !important;
		}
		.today {
			color: #676b71;
		}
		
		.selected{color: #fff !important;}
		.out-of-month {
			background-color:transparent !important;
			color: #efefef;
		}
	} 
	.calendar-day:hover, .selected {
	}
}

.calendarWidget{
    padding: 10px;
}

/*
|===============================================================
|	Calendar Filter
|===============================================================
*/

.CalendarFilter{
    padding: 10px;
}

/* .CalendarFilter{
    span{
        display: inline-block;
        padding: 5px 0px;
        margin: 0px 5px;
        cursor: pointer;
    }
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/*     display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
    display: block;
} */
.CalendarFilter span{
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    color: #333 !important;
    border-bottom: 2px solid transparent;
    margin-bottom: 10px;
}
.CalendarFilter span.active{
    border-bottom: 2px solid $themeColor !important;
    color: $themeColor !important;
}
.noEvents{
    max-width: 500px;
    display: none;
    padding: 20px 10px;
    color: #666 !important;
    @include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
    max-width: 500px;
    margin: 0px !important;
    padding: 0px !important;
}
#allEvents ul li,
#HomepageEvents ul li{
    @include border-radius(5px);
    margin-bottom: 10px;
    background-color: #475f79 ;
    float: left;
    width: 100%;
    clear: both;
    margin-left: 0;
	span.suffix {
		font-size: 12px;
		vertical-align: top;
		margin-left: 2px;
		line-height: 6px;
	}
    @include transition(background 0.35s);
    display: block;
    
    .toggleBtn{
        cursor: pointer;
        padding: 10px;
        padding-left: 20px;
        color: #fff;
        *{
			color: #fff;
        }
		.arrow {
			position: absolute;
			top: 15px;
			right: 15px;
			color: #fff;

		    -webkit-transition-duration: 0.8s;
			-moz-transition-duration: 0.8s;
			-o-transition-duration: 0.8s;
			transition-duration: 0.8s;

			-webkit-transition-property: -webkit-transform;
			-moz-transition-property: -moz-transform;
			-o-transition-property: -o-transform;
			 transition-property: transform;
		}
		&.active {
			.arrow {
				@include transform(rotate(180deg));
			}
		}
    }
}

.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #fff !important;
	}
	.url{
		padding: 10px;
    	color: #333 !important;
    	background-color: #1f5865;
    	@include transition($t1);
    	&:hover{
    		background-color: #4d4d4d !important;
    		color: #fff !important;
    	}
	}
}
.toggleBtn{
	cursor: pointer;
}

.calendar-widget-table thead tr th {
    padding: 10px 20px 10px 20px;
}



.calendar-widget-table thead tr th{
	    background-color: $themeColor !important;
}
#allEvents ul li, #HomepageEvents ul li{
	    background-color: $themeColor !important;
	    *{
	    color: #fff !important;
	    }
}
#HomepageEvents ul li,
#allEvents ul li{
	position: relative;
}
#allEvents ul li .toggleBtn, #HomepageEvents ul li .toggleBtn {
 	padding: 0px !important;
    float: left;
    width: 100%;
}

.calday{
	position: absolute;
    top: 10px;
    left: 19px;
}
.eventTitle{
	padding-left: 110px;
	padding-right: 35px;
	width: 100% !important;
}

.error {
    display: inline-block;
    border: 1px solid #da8486;
    margin: 10px 0px 20px 0px;
    padding: 5px 20px;
    font-size: 1em;
    color: #da8486;
    background-color: #ffdfdf;
}


/* -------------------- General Site layouts -------------------- **/




/* -------------------- Site Build -------------------- **/
#header{
	position: fixed;
	top: 0px;
	z-index: 9999;
	width: 100%;
	@include transition($t1);
	height: 120px;
	background-color: rgba(255,255,255,0.9);
}
.homebtn{
	position: absolute;
	display: inline-block;
	left: 5%;
	top: 50%;
	width: 300px;
	@include transform(translateY(-50%));
	img{
		width: 100%;
	}
}

.LogoTwo{
	position: absolute;
	top: 0px;
	right: 5%;
	width: 150px;
	img{
		width: 100%;
	}
}
.search{
	position: absolute;
	width: 30px;
	top: 25px;
	cursor: pointer;
	right: calc(5% + 170px);
}

.welcomeToggle{
	position: absolute;
	top: 50%;
	width: 45px;
	width: 37px;
	@include transform(translateY(-50%) scale(1,1));
	left: 0px;
	z-index: 99;
	cursor: pointer;
	@include transition(all 100ms);
	&:hover{
		@include transform(translateY(-50%) scale(1,1.1));
	}
}


.QuickLinksToggle{
	height: 194px;
	height: 164px; 

	z-index: 99;
	cursor: pointer;
	@include transition(all 100ms);
	&:hover{
		@include transform(scale(1,1.1));
	}
	position: absolute;
	right: 0px;
	bottom: 0px;
}
.rightLinksContainer{
	z-index: 130;
	position: absolute;
	top: 50%;
	@include transform(translateY(-50%));
	right: 0px;
	min-width: 45px;
	padding-bottom: 220px;
}

.QuicklinksInner{
	max-width: 100vw;
	overflow-y: hidden;
	overflow-x: auto;
	position: absolute;
	right: 0px;
	display: block;
	bottom: 0px;
	opacity: 0;
	@include transition($t1);
	@include transform(translateX(100%));
}
.Quicklinks{
	padding-right: 37px;
	background-color: $themeColor;
	float: left;
	position: relative;
	.Quicklink{
		display: block;
		float: left;
		position: relative;
		height: 194px;
		height: 164px;
		background: $themeColor;
	 	width: 150px;
	 	a{
			position: absolute;
			left: 0px;
			top: 0px;
			width: 100%;
			height: 100%;
		}
	 	.QuicklinkInner{
	 		position: absolute;
	 		left: 50%;
	 		text-align: center;
	 		width: 95%;
	 		top: 50%;
	 		@include transform($center);
	 		img{
	 			margin-bottom: 30px;
	 			height: 65px;
	 			@include transform(rotate(0deg) scale(1));
	 			@include transition(all 100ms);
	 		}
	 		color: #fff;
	 		*{color: #fff;}
	 		font-weight: bold;
	 	}

		&:hover{
			
		 	.QuicklinkInner img{
				@include transform(rotate(10deg) scale(1.1));
			}
		}
	}

}

.Quicklink-1{
	background-color: #6589a0 !important;
}
.Quicklink-2{
	background-color: #ffbf18 !important;
}
.Quicklink-3{
	background-color: #8bc500 !important;
}
.Quicklink-4{
	background-color: #ca0d34 !important;
}
.Quicklink-0{
	background-color: #a8acad !important;
}
.ActiveLinks{
	.QuicklinksInner{
		opacity: 1;
		@include transform(translateX(0%));
	}
}

.bannerText{
	position: absolute;
	bottom: 50px;
	left: 50%;
	@include transform(translateX(-50%));
	width: 80%;
	z-index: 99;
	font-size: 5em;
	.textSection{
	    line-height: 1em;
		position: relative;
		padding-bottom: 20px;
		margin-bottom: 30px;
		&:before{
			position: absolute;
			display: block;
			height: 10px;
			background-color: $themeColor;
			content: '';
			bottom: 0px;
			left: 0px;
			width: 5%;
		}
	}
	*{
		color: #fff;
		font-weight: bold;
	}
	.scrollDown{
		width: 30px !important;
	}
}

.bannerText-2{
	.textSection{
		&:before{
		background-color: #ffbf18;
		}
	}
}
.bannerText-3{
	.textSection{
		&:before{
		background-color: #ca0d34;
		}
	}
}
.bannerText-0{
	.textSection{
		&:before{
		background-color: #8bc500;
		}
	}
}

.scrollDown{
	width: 30px;
}

.col-five{
	float: left;
}

.bottomDecor{
	position: absolute;
	bottom: 0px;
	width: 100%;
	left: 0px;
	z-index: 99;
	.col-five{
		padding-bottom: 20px;
	}
}
.dec1{
	background-color: #6589a0;
}
.dec2{
	background-color: #ffbf18;
}
.dec3{
	background-color: #8bc500;
}
.dec4{
	background-color: #ca0d34;
}
.dec5{
	background-color: #a8acad;
}

.banner{
	.bottomDecor{
		opacity: 0.7;
	}
}

.welcomeContainer{
	width: 58%;
	min-width: 1030px;
	position: absolute;
	top: 180px;
	left: 0px;
	z-index: 999;
	opacity: 0;
	@include transform(translateX(-100%));
	@include transition($t1);
	background-color: #fff;
	padding: 70px 50px 70px 0px;
	.WelcomeInner{
		max-height: calc(50vh - 100px);
		overflow: hidden;
		overflow-y: auto;
		padding-left: 30%;
	}
	.HeadImage{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 28%;
		height: 60%;
		background-size: cover;
		background-position: center; 
		background-image: url(../images/bg.jpg);
	}
	.WelcomeTitle{
		margin-bottom: 20px; 
		padding-bottom: 20px;
		font-weight: bold;
		font-size: 3.5em;
		border-bottom: $themeColor 1px solid;
		img{
			height: 70px; 
		}
		*{
			font-weight: bold;
			display: inline-block;
			vertical-align: middle;
			
		}
	}
	.WelcomeContent{
		font-size: 1.1em;
		padding-left: 30px;
	}
	.CloseWelcome{
		display: inline-block;
		position: absolute;
		top: 20px;
		right: 20px;
		width: 35px;
		cursor: pointer;
		@include transition(all 100ms);
			@include transform(scale(1));
		&:hover{
			@include transform(scale(1.1));
		}
	}
}
.ActiveWelcome{
	.welcomeContainer{
		opacity: 1;
		@include transform(translateX(0%));
	}
}

#Home2{
	text-align: center;
	padding: 100px 0px;
	.pageTitle{
		margin-bottom: 70px;
	}
}

.pageTitle{
	font-size: 4em;
	    line-height: 0.7em;
	*{
		font-weight: bold;
		display: inline-block;
		vertical-align: middle;
	}
}
.PartOne{

}
.PartTwo{
	padding: 0px 10px 10px 10px;
	color: #fff;
	background-color: $themeColor;
}
 
.textSectionPartOne{ 
display: inline-block;
font-size: 0.7em;
	padding: 0px 10px 0px 10px;
	color: #fff;
	background-color: $themeColor;
}
.Prev{
	display: inline-block;
	position: relative;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}
.Next{
	display: inline-block;
	position: relative;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.NewsSlideInner{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	overflow: hidden;
	background-image: url(../images/bg.jpg);
	.HoverSection{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		opacity: 0;
		@include transition($t1);
		&:before{
			content: '';
			display: block;
			width: calc(100% - 12px);
			height: calc(100% - 12px);
			top: 0px;
    	 	position: absolute;
			left: 0px;
			border: 6px solid #ca0d34;
			z-index: 99;
		}
		.HoverSectionTitle{
			background-color: #ca0d34;
			position: absolute;
			@include transform(rotate(-14deg));
	    	width: 100%;
	    	text-align: left;
	    	padding: 10px 40px 10px 60px;
	    	left: -20px;
	    	top: 0px;
	    	font-size: 1.2em;
			color: #fff;
			font-weight: bold;
			z-index: 9;
			@include box-shadow(0px 10px 10px 0px rgba(0,0,0, 0.6));

			&:before{
				content: '•';    
				font-size: 1.4em;
				color: #fff;
				text-align: center;
				left: 30px;
				position: absolute;
				line-height: 0.7em;
				top: 50%;
				@include transform(translateY(-50%));
				display: block;
				width: 20px;
				height: 20px;
				@include border-radius(50%);
				border: 3px solid #fff;
			}
		}
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 104;
	}
	.hoverBg{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		@include transition($t1);
		background-color: rgba(89, 126, 151, 0.63);
	}
	&:hover{
		.HoverSection{
			opacity: 1;
		}
		.hoverBg{
			background-color: rgba(89, 126, 151, 0.9);
		}
	}
	.HoverInfo{
		z-index: 100;
		position: absolute;
		top: 50%;
		width: 95%;
		left: 50%;
		@include transform($center);
	}
	.HoverTitle{ 
		color: #fff;
		font-weight: bold;
		font-size: 1.4em;
	}
	.HoverContent{
		color: #fff;
		*{color: #fff;}
		@include transition($t1);
		overflow: hidden;
	}
}

.NewsSlideInner-0{
	.hoverBg{ 
		background-color: rgba(64, 93, 113, 0.85) !important;
	}
}

.readMore{
	min-width: 200px;
}
.calendar-widget-table .prev,
.calendar-widget-table .next,
.Prev,
.Next,
.readMore{
	border: 2px solid #dadcdc;
	padding: 10px 20px;
	*{color: $themeColor;}
	color: $themeColor;
	display: inline-block;
	@include transition($t1);
	min-height: 45px;
	vertical-align: middle;
	font-size: 0.9em;
	&:hover{
		border: 2px solid $themeColor;
		background-color: $themeColor;
		color: #fff;
		*{color: #fff;}
	}
}
.calendar-widget-table {
	td{
		border: none !important;
	}
	tbody{
		font-size: 1.3em !important;
	}
}
.calendar-widget-table thead tr th{
	background-color: transparent !important;
}
.calendar-widget-table .prev,
.calendar-widget-table .next,
.Prev,
.Next{
	margin: 0px 10px;
	i{
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform($center);
		font-size: 1.5em;
		@include transition($t1);
	}
}

.NewsSliderBottom{
} 

#Home3{
	background-color: #a4a4a5;
	
}

a.important{
	color: #c00;
	font-weight: bold;
}

#tickerContainer{
	clear: both;
	position: relative;
	bottom: 0;
	padding: 0px;
	width: 100%;
	overflow: hidden;
	max-height: 77px;
}



.tickertape{
	float: left;
	position: relative;
	overflow: hidden;
}
 




.tickertape section{

	span{
		padding: 15px 0;
	}

	*{
		color: #fff;
		display: inline-block;
		vertical-align: middle;
	}
	white-space: nowrap;
	display:inline-block;
	float: left;
	padding: 15px 0;
	position: relative;
	text-align: center;
	color: #fff;
	letter-spacing: 1px;
	font-size: 14px;
	a{
		display: inline-block;
		vertical-align: middle;
		border: 1px solid #fff;
		padding: 5px 15px;
		color: #fff;
		@include transition($t1);
		&:hover{
			background-color: $themeColor;
			border-color: $themeColor;
		}
	}
}

.ticker{
	position: relative;
	float: left;
	top: auto;
	max-height: 77px;
	width: 100%;
}

#LatestNews{
	top: 50%;
	@include transform(translateY(-50%));
	position: absolute;
    left: 0px;
    padding-left: 10px;
    height: 46px;
    background-color: $themeColor;
    color: #fff;
    z-index: 2;
    line-height: 45px;
    font-size: 1em;
    font-weight: bold;
    padding-right: 10px;
}

.FooterDecor{
	position: relative;
	left: auto;
	bottom: auto;
	float: left;
	width: 100%;
}
#Home4{
	padding: 100px 20px;
}

.CalContent{
	padding: 15px 20px;
	padding-left: 110px;
	background: #f0f1f1;
	a{
		font-size: 0.9em;
		text-transform: uppercase;
		letter-spacing: 2px;
        color: #6e6e70;
	}
}

.CalDate{
	z-index: 9;
	position: absolute;
	left: 0px;
	width: 90px;
	height: 100%;
	text-align: center;
	background-color: $themeColor;
	*{
		color: #fff;
	}
}
.CalDateInner{
	*{
		font-size: 1em;
	}
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform($center);
}

.CalTitle{
	font-weight: bold;
	margin-bottom: 15px;
}

.CalLeft{
	padding-right: 5%;
}
.CalRight{
	padding-left: 5%;
	padding-bottom: 30px;
}
.CalDay{
	font-size: bold;
	font-size: 1.6em;
}
.CalMonth{
	font-size: bold;
}

.calControls{
	@include transform(scale(0.7));
	a{
		position: relative;
		font-size: 0.9em !important;
        color: #6e6e70;
	}
	position: absolute;
	right: 10%; 
	top: 0px;
	z-index: 9;
}
.calendar-header-day{
	font-size: 0.7em !important;
	text-transform: uppercase;
}
.calendar-widget-table tbody tr td{
	padding: 20px 0px !important;
}

#Home4{
    .pageTitle{line-height: 0.9em;} 
    .inner{
    	width: 1000px;
    }
}

.SixthForm,
.HomePage{
	.calEventPopup{
		display: none !important;
	}
}

.CalendarImage{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 50%;
	height: 100%;
	background-image: url(../images/bg.jpg);
	background-size: cover;
	background-position: center;
}
.CalendarGrad{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.53+0,1+82,1+100 */
background: -moz-linear-gradient(left, rgba(255,255,255,0.53) 0%, rgba(255,255,255,1) 82%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left, rgba(255,255,255,0.53) 0%,rgba(255,255,255,1) 82%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, rgba(255,255,255,0.53) 0%,rgba(255,255,255,1) 82%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}


#Home5{
	text-align: center;
	padding: 100px 20px;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
	background-image: url(../images/bg.jpg);

	.darken{
		@include stretchCover($themeColor, absolute, 1);
		opacity: 0.7;
	}
	.inner{
		z-index: 9;
	}

	.pageTitle{
		*{
			color: #fff;
		}
		.PartTwo{
			background-color: #6e6e70;
		}
	}
	.inner{
		width: 1200px;
	}

    .pageTitle{line-height: 0.9em;} 
    .col-3{
    	padding: 0px 3%;
    	opacity: 0;
	 @include transform(scale(1));
    }
}

#Home5.Active{
	 .col-3{
	 @include transform(scale(0.8));
    	opacity: 1;
	 }
}

.HousePointCircle{
	padding-bottom: 100%;
	background-color: #fff;
	@include border-radius(50%);
	overflow: hidden;
	&:before{
		content: '';
		display: block;
		width: 70%;
		height: 70%;
		position: absolute;
		background-color: #fff3d6;
		background-color: #d9ddf1;
		top: 50%;
		left: 50%;
		@include transform($center);
		@include border-radius(50%);
	}
	&:after{
		content: '';
		display: block;
		width: calc(100% - 20px);
		height: calc(100% - 20px);
		position: absolute;
		top: 50%;
		left: 50%;
		border: 11px solid #ffbf18;
		border: 11px solid #5572ff;
		@include transform($center);
		@include border-radius(50%);
	}
	span{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		font-size: 3.6em;
		font-weight: bold;
		@include transform($center);

	}
}

.HousePointsTitle{
	font-size: 2.7em;
	color: #fff;
	padding-bottom: 20px;
	font-weight: bold;
	margin-top: 30px;
	&:after{
		content: '';
		display: block;
		height: 8px;
		width: 35%;
		position: absolute;
		background-color: #ffbf18;
		background-color: #5572ff;
		left: 50%;
		bottom: 0px;
		@include transform(translateX(-50%));
	}
}
.HousePointCircle-2{
	&:before{
		background-color: #f4d5da;
		background-color: #fff3d6;
	}

	&:after{
		border: 11px solid #ca0d34;
		border: 11px solid #ffbf18;
	}
}

.HousePoints-2 .HousePointsTitle{
	&:after{
		background-color: #ca0d34 !important;
		background-color: #ffbf18 !important;
	}
}

.HousePointCircle-3{
	&:before{
		background-color: #e9f4d2;
		background-color: #f4d5da;
	}

	&:after{
		border: 11px solid #8bc500;
		border: 11px solid #ca0d34;
	}

	
}
.HousePoints-3 .HousePointsTitle{
	&:after{
		background-color: #8bc500 !important;
		background-color: #ca0d34 !important;
	}
}
.HousePointCircle-0{
	&:before{
		background-color: #eeefef;
	}

	&:after{
		border: 11px solid #a8acad;
	}

	
}
.HousePoints-0 .HousePointsTitle{
	&:after{
		background-color: #a8acad !important;
	}
}

.dib{
	vertical-align: middle;
	display: inline-block;
}

footer{
	padding: 50px 5%;
}

.footerLogo{
	padding-right: 40px; 
	img{
		height: 80px;
	}
}

.footerLabel{
	font-weight: bold;
	color: $themeColor;
}
.emailFooter {
    color: #000;
}
.divider{
	display: inline-block;
	padding: 0px 10px;
}

.fse{
	position: absolute;
	right: calc(5% + 60px);
	bottom: 50px;
	font-size: 0.8em;
    color: #6e6e70;
	*{
		font-size: 1em;
        color: #6e6e70;
	}
}

.backtoTop{
	position: absolute;
	bottom: 50px;
	right: 5%;
	cursor: pointer;
	width: 28px;
}


.UsefulLinks{
	float: right;
	width: 100%;
	position: relative;
}
.UsefulLink{
	float: right;
	clear: both;
	padding-left: 37px;
	height: 37px;
	background-color: #fff;
	position: relative;
	margin-bottom: 7px;
	.InnerLink{
		min-width: 0px !important;
		overflow: hidden;
		width: 0px;
		background-color: #ffbf18;
		height: 37px;
		@include transition($t1);
		.col-12{
			color: #fff;
    		padding: 7px 10px;
		}
	}
	&:hover{	
		.InnerLink{
			width: 200px !important;
		}
	}
	.icon{
		position: absolute;
		width: 25px;
		left: 18px;
		top: 50%;
		@include transform($center);
		@include transition(all 100ms);
	}
	a{
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
	}
}

/**/
.SearchContainer{
    display: none;
    position: fixed;
    top: 75px;
    background-color: $themeColor;
    padding: 7px;
    right: 0px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
    	background-color: $themeColor;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
 

 	-webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;

	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
    	@include border-radius(0px !important);
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 6px;
	    color: #fff !important;
	    font-style: italic;
    	background-color: $themeColor;
    	-webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px 7px 10px;
	    color: #5f5e6d;
	    float: left;
    	@include border-radius(3px !important);
    	display: block;
    }
} 

#noticeSliderContainer{
	text-align: center;
	.bx-pager-item{
		display: inline-block;
		margin: 5px;
	}
	.bx-pager-link{
		display: inline-block;
		width: 10px;
		height: 10px;
		@include transition($t1);
		color: transparent;
		background-color: #cdcdcd;
		@include border-radius(50%);
		&:hover{
			background-color: #2b889c;
		}
	}
	.bx-pager-link.active{
		background-color: #2b889c;
	}
}

.noticesContainer{
	.pageTitle{
		line-height: 1em;
		font-size: 2em;
	}
}

.InnerPage .banner{
	#Home3{
		background-color: rgba(123, 123, 127, 0.81);
		position: absolute;
		bottom: 20px; 
		left: 0px;
	}
	.bottomDecor{
		opacity: 1;
	}
}

.InnerPage{
	#header{
		background-color: #fff;
	}
	.pageTitle{
		line-height: 1em;
	}
}

#breadcrumbs{
	padding: 10px;
	.here{
		font-weight: bold;
	}
}

#ValuesContainer{
	background-color: $themeColor;
	padding: 40px 20px;
	margin-bottom: 40px;
	*{
		color: #fff;
	}
}

					
.ValuesBox{
	padding: 10px 0px 10px 30px;
	.bullet{
		position: absolute;
		top: 10px;
		left: 10px;
	}
}


.FileUpload{
	width: 100%;
	display: block;
	position: relative;
	padding: 10px 20px;
	clear: both;
	margin-bottom: 10px;
	@include transition($t1);
		color: #207d92;
	*{
		color: #207d92;
	}
	border: 2px solid #e9f2f4;
	border-left: none;
	&:before{
		content: '';
		display: block;
		height: calc(100% + 4px);
		position: absolute;
		width: 8px;
		background-color: $themeColor;
		left: 0px;
		top: -2px;
	}
	&:hover{
		background-color: #f2f2f2;
	}
}

.FileUploadPage{
	border: 3px solid #dadcdc;
	padding: 15px 40px 15px 20px;
	position: relative;
	width: 100%;
	margin-bottom: 10px;
	@include transition($t1);
	display: block;
		color: #207d92;
	*{
		color: #207d92;
	}
	i{
		position: absolute;
		top: 50%;
		right: 15px;
		font-size: 1.2em;
		@include transform(translateY(-50%));
	}

	&:hover{
		background-color: #f2f2f2;
	}
}



#PageSlider{

}
.PageSliderInner{

} 
.SlideImg{    
	z-index: 8;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 50%;
	height: 100%;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
	img{
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 100;
		@include transform($center);
		width: 40px;
	}
	.darken{
		@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
	}
}
.PageSliderContent{
	padding: 20px 20px 20px calc(50% + 20px);
	background-color: $themeColor;
	*{
		color: #fff;
	}
}
.PageSliderTitle{
	font-size: 2em;
	text-transform: uppercase;
	margin-bottom: 15px;
}
.PageSliderTextOne{
	padding-bottom: 20px;
	border-bottom: 1px solid #fff;
}
.PageSliderTextTwo{
	padding-bottom: 20px;
}
.PageSliderLink{
	a{
		display: inline-block;
		border: 2px solid #fff;
		padding: 10px 20px;
		color: #fff !important;
		@include transition($t1);
		&:hover{
			background-color: #fff;
			color: $themeColor !important;
		} 
	}
}


.PageSliderTopTitle{
    padding: 20px 0px;
    font-size: 2em;
    font-weight: bold;
    border-top: 1px solid $themeColor;
}
.innerContent{
	padding-right: 10%;
}


.pageList{
	a{
		display: block;
		margin-bottom: 5px;
		background-color: #eee;
		padding: 10px 20px;
		color: #475f79 !important;
		@include transition($t1);
		&:hover{
			background-color: #475f79 !important;
			color: #fff !important;
		} 
	}
}

#__toolbox-opener {
    z-index: 999999 !important;
    top: 91px !important;
}
.fancybox-opened {
    z-index: 99999 !important;
}
.fancybox-overlay {
    z-index: 9999;
}

.cookieWarning{
	width: 100% !important;
}


#ListingPageContainer{
	height: calc(100vh - 90px);
	.ListingPageLeft{
		background-color: #fff;
		border-right: 5px solid #fff;
		.ListingPageLeftInner{
			padding: 20px;
			position: absolute;
			bottom: 100px;
			left: 50%;
			width: 80%;
			overflow: hidden;
			overflow-y: auto;
			max-height: calc(100% - 220px);
			@include transform(translateX(-50%));
		}
	}
	.ListingPageRight{
		padding: 20px;
		background-position: center;
		background-size: cover;
		background-image: url(../images/bg.jpg);
		.ListingPageRightInner{
			z-index: 4;
			padding: 20px;
			position: absolute;
			bottom: 100px;
			left: 50%;
			width: 80%;
			overflow: hidden;
			overflow-y: auto;
			max-width: 800px;
			max-height: calc(100% - 90px);
			@include transform(translateX(-50%));
		}
		.darken{
			@include stretchCover(rgba(255, 255, 255, 0.84), absolute, 1)
		}
	}
}

.ListingPage{
	#header{
		background-color: #f2f2f2;
	}
}

.ListingPage{
	padding-top: 90px;
	.pageTitle{
		font-size: 2.6em;
	}
}
 
.ListingBox{
	padding-bottom: 100%;
	@include transition(all 100ms);
	@include border-radius(50%)
	@include transform(scale(1));
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	&:hover{
		@include transform(scale(1.1));
	}
}
.ListingTitle{
	color: #fff;
	position: absolute;
	top: 50%;
	text-align: center;
	left: 50%;
	@include transform($center);
	width: 80%;
}

.ListingBox-1{
	background-color: rgba(255, 191, 24, 0.7);
}
.ListingBox-2{
	background-color: rgba(202, 13, 52, 0.72);
}
.ListingBox-3{
	background-color: rgba(139, 197, 0, 0.75);
}
.ListingBox-0{
	background-color: rgba(101, 137, 160, 0.8);
}

.NewsLink{
	border: 2px solid #fff;
	display: inline-block;
	text-align: center;
	margin-top: 10px;
	padding: 5px 30px 10px 30px;
	color: #fff;
}


.SixthFormInnerPage,
.SixthForm{
	#header {
	    background-color: rgba(99, 99, 101, 0.81);
	}
	#Menu .topMenuLink:after{
		background-color: #fff !important;
	}
	.topMenuLink{
		color: #fff !important;
	}
	.OverlayNumber{
		position: absolute; 
	    font-size: 47vw;
	    right: 2vw;
	    bottom: -7vw;
	    width: 407px;
	    font-weight: bold;
	    color: #fff;
	}

	footer{
	    background-color: rgba(99, 99, 101, 1);
		*{
			color: #fff !important;
		}
	}


	#MenuIcon{
		span{
			background-color: #fff !important;
		}
	}

	#sidebar .sidebar-heading {
	    background-color: #636365 !important;
	}
	#sidebar {
	    border-bottom: 3px solid #636365 !important;
	}

}

.SixthFormInnerPage{
	footer,
	#header {
	    background-color: rgba(99, 99, 101, 1);
	}
}

.search2{
	right: calc(5% + 336px);
}

.backbtn{
	position: absolute;
	top: 0px;
	right: calc(5% + 170px);
	display: inline-block;
	img{
		height: 55px;
	}
}
@media (max-width: 1200px){
	.backbtn {
	    right: 305px;
	}
}
@media (max-width: 696px){
	.backbtn {
	    display: none;
	}
}

#StudentQuotes{
	.quoteMark{
		@include transform(translateX(-100%));
	    position: absolute;
	    color: #fff;
	    left: 0px;
	        top: -342px;
	    font-size: 29em;
	    img{
	    	width: 200px;
	    }
	}
	padding: 180px 0px;
	.darken{
		@include stretchCover(rgba(0,0,0, 0.7), absolute, 1)
	}
	.inner{
		z-index: 9;
		width: 700px;
	}
	*{
		color: #fff;
	}

	.StudentQuote{
		font-size: 2.4em;
		padding-bottom: 20px;
	}
}

.pageLink{
	position: relative;
	width: 100%;
	clear: both;
	margin-bottom: 20px;
	height: 80px;
	display: block;
	background-color: #ca0d34;
	@include transform(scale(1));
	@include transition(all 100ms);
	&:hover{@include transform(scale(1.05));}
}
.pageLink-1{
	background-color: #6589a0;
}
.pageLink-2{
	background-color: #ffbf18;
}
.pageLink-3{
	background-color: #8bc500;
}
.pageLink-4{
	background-color: #ca0d34;
}
.pageLink-0{
	background-color: #a8acad;
}
.PageLinkInner{
	position: absolute;
	text-align: right;
	top: 50%;
	left: 50%;
	width: 85%;
	@include transform($center);
	span{
		color: #fff;
		padding-left: 50px;
	}
	img{
		height: 40px;
		position: absolute;top: 50%;
		left: 0px;
		@include transform(translateY(-50%));
	}
}

.RecommendedLink{
	border: 3px solid #dadcdc;
	padding: 15px 20px 15px 70px;
	position: relative;
	display: block;
	width: calc(50% - 10px);
	float: left;
	margin-bottom: 20px;
	@include transition(all 100ms);
	*{@include transition(all 100ms);}
	&:hover{
		border: 3px solid $themeColor;
		.ArrowContainer{
			background-color: $themeColor;
		*{
				color: #fff;
			}
		}

	}
}
.RecommendedTitle{
	padding: 40px 0px 30px 0px;
	font-weight: bold;
	font-size: 1.8em;
	letter-spacing: -1px;
	color: #636365;
}
.RecommendedLink-1{
	margin-right: 10px;
}
.RecommendedLink-0{
	margin-left: 10px;
}
.ArrowContainer{
	background-color: #dadcdc;
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 50px;
	i{
		font-size: 1.3em;
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform($center);
	}
	*{
		color: $themeColor;
	}
}

.twitterLink{
	border: 1px solid #efefef;
    padding: 2px 10px 4px 10px;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
    margin-bottom: 20px;
    @include box-shadow(0px 1px 2px 0px #d6d6d6);
    @include border-radius(4px);

		color: #1da1f2;
	*{
		vertical-align: middle;
		display: inline-block;
		color: #1da1f2;
	}
	span{
		font-size: 0.8em;
	}
} 

.PageImage{
    padding-bottom: 120%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(../images/bg.jpg);
}


.loadingSection{
	position: fixed;
	top: 0px;
	left: 0px;
	background-color: #fff;
	width: 100%;
	z-index: 99999;
	height: 100%;
	img{
		width: 40px;
		top: 50%;
		left: 50%;
		position: absolute;
		@include transform($center);
	}
}
.pace {
	display: none !important;
}

/* -------------------- Site Build -------------------- **/



