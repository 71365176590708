
/* ------------  General Site Responsive ----------- */
@media all and (min-width: 1601px)  {
	/*
	#MobileNavigation{
		display: none !important;
	}
	*/
}
@media all and (max-width: 1660px)  {
	.SixthFormInnerPage,
	.SixthForm{
		#MenuIcon{
			display: block !important;
		}
		#Menu{
			display: none !important;
		}

		.LogoTwo{
			right: 147px;
		}
		.search {
	    	right: 105px;
	    }

	    #header{
	    	height: 63px;
	    }

	    .search{
	    	right: 88px;
	    	top: 16px;
	    }
	    .homebtn{
	    	left: 20px;
	    	width: 200px;
	    }
	    .SearchContainer {
	    	top: 18px;
	    }
	    .backbtn {
	    	right: 306px;
	    }
    }
}

@media all and (max-width: 1133px)  {
	.cleaFac{
		width: 100%; clear: both !important;
	}
}
@media all and (max-width: 1200px)  {
	
		#MenuIcon{
			display: block !important;
		}
		#Menu{
			display: none !important;
		}

		.LogoTwo{
			right: 170px; //147px;
		}
		.search {
	    	right: 105px;
	    }

	    #header{
	    	height: 63px;
	    }

	    .search{
	    	right: 88px;
	    	top: 16px;
	    }
	    .homebtn{
	    	left: 20px;
	    	width: 200px;
	    }
	    .SearchContainer {
	    	top: 18px;
	    }
	    .backbtn {
	    	right: 306px;
	    }
    .translateHolder {
        right: 125px; //calc(5% + 73px);
        top: 16px;
    }
    
}


@media all and (max-width: 786px)  {
	.tacMid{
		text-align: center !important;
	}
	.clearMid{
		width: 100%;
		padding: 0px !important;
		clear: both;
	}
	
	.sideBarContainer{
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	.PageImage,
	#sidebar{
	display: none !important;
	}
	.innerContent{
		padding-right: 10px;
	}
}
/*** Newsletters Page Responsive ***/
@media all and (max-width: 726px)  {
	.NewsletterItem{
		width: 50% !important;
	}
}
@media all and (max-width: 500px)  {
    .NewsletterItem{
		width: 100% !important;
	}
}
/*** Newsletters Page Responsive ***/

/*** Staff Responsive ***/
@media all and (max-width: 520px)  {
	.StaffPage .staffProfile .profilePic {
	    margin: 0 0px 30px 0 !important;
	    float: left;
	    width: 100%;
	    clear: both;
	    display: block;
	}
}
/*** Staff Responsive ***/
/*** Gallery Responsive ***/

@media all and (max-width: 800px)  {
    .galleryImage{
        width: 50% !important;
        padding-bottom: 50% !important;
    }
}
@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}
}
@media all and (max-width: 500px)  {
    .galleryImage{
        width: 100% !important;
        padding-bottom: 100% !important;
    }
}

@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
}
/*** Gallery Responsive ***/




@media all and (max-width: 555px)  {
	.LogoTwo{
		display: none;
	}
}

@media all and (max-width: 400px)  {
	.homebtn{
		width: 150px;
	}
}


@media all and (max-width: 1040px)  {
	.welcomeContainer{
		min-width: 0px;
		position: fixed;
		width: 100% !important;
		top: 60px;
		z-index: 99999;

		.WelcomeInner{
		    max-height: calc(100vh - 200px);
		}
	}
}


@media all and (max-width: 790px)  {
	.HeadImage{
		display: none;
	}
	.welcomeContainer .WelcomeInner{
		padding: 0px !important;
	}
	.welcomeContainer .WelcomeTitle {
    	font-size: 2em;
    }
    .welcomeContainer .WelcomeTitle img {
	    height: 46px;
	}
	.welcomeContainer {
   		padding: 70px 30px 70px 0px;
		.col-6{
			width: 100%;
			padding: 0px !important;
		}
	}
}


@media all and (max-width: 1010px)  {
	#Home5{
		.col-3{
			width: 50%;
			    padding: 20px 10%;
			    font-size: 0.6em;
		}
	}
}
@media all and (max-width: 475px)  {

#LatestNews{
	display: none;
}
.calendar-widget-table thead tr th .show-month{
		font-size: 1.2em;
	}
	#Home5{
		.col-3{
			width: 100%;
			    padding: 20px 20%;
		}
	}

	.readMore {
    	min-width: 0px;
	}

	.bannerText{
		font-size: 1.7em !important;
	}
}
@media all and (max-width: 978px)  {

	.pageTitle{
    	line-height: 1em !important;
    }
	footer{
	.flex{
		display: block;
	}
	text-align: center;
		.dib{
			display: block;
			float: left;
			clear: both;
			width: 100%;
		}
	}
	.fse{
		position: relative;
		width: 100%;
		text-align: center;
		bottom: auto;
		right: auto;
		    float: left;
	}
	.backtoTop{
	display: none;
	}
	.footerLogo img {
	    height: 40px;
	}
	.footerLogo {
	    padding-right: 0px;
	}
	.bannerText {
	    font-size: 3em;
	    width: 100%;
	    text-align: center;
	}
	.scrollDown{
		display: none;
	}

	.bannerText .textSection:before {
		left: 50%;
		@include transform(translateX(-50%));
	    width: 70px;
	}
	.pageTitle {
   		font-size: 2.4em;
    }
    .CalendarImage{
    	display: none;
    }

}
@media all and (max-width: 500px)  {
	.SlideImg{
		display: none !important;
	}
	.PageSliderContent {
    padding: 20px  !important;
    }
}
@media all and (max-height: 700px)  {
	.UsefulLinks{
		/*display: none; */
	}
	.rightLinksContainer{
		padding-bottom: 194px;
	}

	.rightLinksContainer {
		top: auto !important;
	    bottom: 20px !important;
	    @include transform(translateY(0px) !important);
	}
}

@media all and (max-height: 560px)  {
	.UsefulLinks{
		display: none;
	}
}
@media all and (max-width: 830px)  {


	.calControls {
   		right: 0px !important;
    }
	.CalRight,
	.CalLeft{
		width: 100%;
		clea: both;
		padding: 0px !important;
	}
	.CalRight{
		margin-bottom: 40px;
	}
}

@media all and (max-width: 1030px)  {
	.parallax{
		top: 0px !important;
		margin-top: 0px !important;
		opacity: 1 !important;
		@include transform(translate(0px, 0px) !important);
	}
	*{
		background-attachment: initial !important;
		-webkit-background-attachment: initial !important;
	}
}


@media all and (max-width: 1260px)  {
	.ListingPageRight,
	.ListingPageLeft{
		min-width: 0% !important;
		width: 50%;
	}
	.ListingPageRightInner{
			bottom: 0px !important;
			width: 100% !important;
			max-height: 100% !important; 
		.col-3{
			width: 33.33%;
		}
	}
}


@media all and (max-width: 1260px)  {
	#ListingPageContainer{
		display: block;
		height: auto !important;
	}
	.ListingPageRight,
	.ListingPageLeft{
		width: 100%;
	}
	.ListingPageLeftInner{
		padding-top: 60px !important;
	}
	.ListingPageLeftInner,
	.ListingPageRightInner{
		top: auto !important;
		position: relative !important;
		bottom: auto !important;
		left: auto;
		width: 100% !important;
		max-height: none !important;
		float: left !important;
	}
}

@media all and (max-width: 650px)  {
	
	.RecommendedLink{
		width: 100% !important;
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.RecommendedTitle {
   		font-size: 1.2em;
    }
}
@media all and (max-width: 580px)  {
	.ListingPageRightInner{
		.col-3{
			width: 50%;
		}
	}
}
@media all and (max-width: 400px)  {
	.ListingPageRightInner{
		.col-3{
			width: 100%;
		}
	}
}

@media all and (max-width: 330px)  {
    .translateHolder {
        display: none;
    }
}
/* ------------  General Site Responsive ----------- */
